import React from "react"
import { Row, Col, Container, ResponsiveEmbed } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout pageInfo={{ pageName: "index" }}>
    <SEO title="Home" keywords={[`Austin`, `motorcycle`, `shop`, `Austin Motorcyle Shop`]} />

    <section className="d-flex flex-column align-items-center justify-content-center min-vh-100 bg-black">
      <figure class="w-100 bg-primary">
        <img className="w-100 mix-blend-mode-multiply" src="/images/austin-moto--winged-tire.jpg"></img>
      </figure>
    </section>

    <section class="d-flex flex-column align-items-center justify-content-center min-vh-100 py-2 bg-black text-primary text-center">
      <h1 class="mb-2 text-uppercase">
        Services for Harley-Davidson, Ducati, Triumph, and other makes
      </h1>
      <h3 class="text-uppercase">
        Since 2007
      </h3>
    </section>

    <Container className="mb-4">

      <Row id="section-maintenance" className="min-vh-100 align-items-center py-2 text-uppercase">
        <Col sm="6" className="">
          <h1 className="">Maintenance</h1>
          <p className="">
            Oil changes, valve adjustments, timing belts, suspension, carburetor and fuel injection systems, electrical, brakes tires, and more. We can perform extended warranty maintenance for Harley-Davidson, including wheels and tires.
          </p>
        </Col>
        <Col sm="6" className="">
          <img className="w-100 mix-blend-mode-multiply" src="/images/motorcycle--harley-softail-slim.png"></img>
        </Col>
      </Row>

      <Row id="section-parts" className="min-vh-100 align-items-center py-2 text-uppercase">
        <Col sm="6" sm={{ order: 'last' }} className="text-md-right">
          <h1 className="">Parts</h1>
          <p className="">
            Stock replacement, performance upgrades, intake, exhaust, cams, etc. <a href="https://www.parts-unlimited.com/" target="_blank">Parts Unlimited</a>, <a href="https://www.dragspecialties.com/" target="_blank">Drag Specialties</a>, <a href="https://www.tucker.com/" target="_blank">Tucker</a>, <a href="https://www.ohlins.com/" target="_blank">Ohlins</a>, <a href="https://www.fuelmotousa.com/" target="_blank">Fuel Moto</a>, <a href="https://www.motul.com/" target="_blank">Motul</a>, <a href="http://www.thunderheader.net/" target="_blank">Thunderheader</a>, and more!
          </p>
        </Col>
        <Col sm="6" className="">
          <img className="w-100 mix-blend-mode-multiply" src="/images/motorcycle--ducati-01.png"></img>
        </Col>
      </Row>

      <Row id="section-restoration" className="min-vh-100 align-items-center py-2 text-uppercase">
        <Col sm="6" className="">
          <h1 className="">Restoration</h1>
          <p className="">
            Return that old bike back to its mechanical glory and make it beautiful again.​ We provide restoration services to fit your budget. From conservative restoration to complete rebuilds.
          </p>
        </Col>
        <Col sm="6" className="">
          <img className="w-100 mix-blend-mode-multiply" src="/images/motorcycle--triumph-vintage.png"></img>
        </Col>
      </Row>

      <Row id="section-custom-builds" className="min-vh-100 align-items-center py-2 text-uppercase">
        <Col sm="6" sm={{ order: 'last' }} className="text-md-right">
          <h1 className="">Custom Builds</h1>
          <p className="">
            We have built bikes from the ground up. Start with just an idea and bring your dream alive. We can transform a bike you already have into something totally different.
          </p>
        </Col>
        <Col sm="6" className="">
          <img className="w-100 mix-blend-mode-multiply" src="/images/motorcycle--honda-cb650.png"></img>
        </Col>
      </Row>

      <Row id="section-sales" className="min-vh-100 align-items-center py-2 text-uppercase">
        <Col sm="6" className="">
          <h1 className="">Sales</h1>
          <p className="">
            We usually have a few bikes available for purchase. Give us a call or stop in to see our inventory.
          </p>
        </Col>
        <Col sm="6" className="">
          <img className="w-100 mix-blend-mode-multiply" src="/images/motorcycle--triumph-bonneville.png"></img>
        </Col>
      </Row>

    </Container>

    <section id="section-location" className="">
      <figure className="google-map">
        <ResponsiveEmbed aspectRatio="16by9">
          <iframe
            title="Google Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d55177.75323838454!2d-97.8440255761586!3d30.191144807420876!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8644b4945b16c517%3A0x99589848109be6c8!2sAustin%20Moto%20Classics!5e0!3m2!1sen!2sus!4v1605617291077!5m2!1sen!2sus"
            width="960"
            height="540"
            frameborder="0"
            allowfullscreen=""
            aria-hidden="false"
            tabindex="0"
          ></iframe>
        </ResponsiveEmbed>
      </figure>
    </section>

    <section className="d-none">
      <img className="w-100 mix-blend-mode-multiply" src="/images/motorcycle--harley-ironhead.jpg"></img>
    </section>

  </Layout>
)

export default IndexPage
